import { severities } from '@shared/utils'

import { Button, Divider, Stack, Typography } from '@mui-components'

import { Property } from '../HealthHistory.utils'

export default function Allergy({ data, editAllowed = false, onEdit }) {
  const testId = `allergy-${data.name}`
  const severity = severities.find((s) => s.value === data.severity)?.label || data.severity

  return (
    <Stack data-testid={testId}>
      <Divider sx={{ mb: 1 }} />
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Typography data-testid={`${testId}-name`}>{data.name}</Typography>
        {editAllowed && (
          <Button size="small" onClick={onEdit} data-testid={`${testId}-edit`}>
            Edit
          </Button>
        )}
      </Stack>
      <Property label="Reaction" value={data.reaction} data-testid={testId} />
      <Property label="Severity" value={severity} data-testid={testId} />
    </Stack>
  )
}
