import { CloseIcon } from '@icons'
import { ListItem, ListItemButton, ListItemText } from '@mui-components'

export default function Title({ label, onClose }) {
  return (
    <ListItem disablePadding onClick={onClose} sx={{ mb: 3 }} data-testid={`${label}-close`}>
      <ListItemButton>
        <ListItemText primary={label} primaryTypographyProps={{ variant: 'h6', fontWeight: 'bold' }} />
        <CloseIcon color="primary" />
      </ListItemButton>
    </ListItem>
  )
}
