import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Button, Dialog, DialogActions, DialogContent } from '@mui-components'

CropDialog.propTypes = {
  /** Open dialog? */
  open: PropTypes.bool,

  /** Close on cancel? */
  closeOnCancel: PropTypes.bool,

  /** Close on saving? */
  closeOnSave: PropTypes.bool,

  /** Action to run on cancel */
  onCancel: PropTypes.func,

  /** Action to run on save */
  onSave: PropTypes.func,

  /** Dialog content */
  children: PropTypes.element.isRequired,
}

export default function CropDialog({ open, closeOnCancel, onCancel, closeOnSave, onSave, children = null }) {
  const testId = 'crop-dialog'

  const [modalOpen, setModalOpen] = useState(open)
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  if (!children) {
    return null
  }

  const handleCancel = (e) => {
    if (closeOnCancel) setModalOpen(false)
    if (onCancel) onCancel(e)
  }

  const handleSave = (e) => {
    if (closeOnSave) setModalOpen(false)
    if (onSave) onSave(e)
  }

  return (
    <Dialog
      open={modalOpen}
      sx={{ minHeight: '500px!important' }}
      PaperProps={{
        sx: { m: 3, p: 2, width: smallScreen ? '300px' : '600px', height: smallScreen ? '400px' : '500px' },
        'data-testid': testId,
      }}
    >
      <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', px: 0 }}>
        <Button onClick={handleCancel} data-testid={`${testId}-cancel`}>
          Cancel
        </Button>
        <Button onClick={handleSave} type="submit" variant="contained" data-testid={`${testId}-save`}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
