import Highlighter from 'react-highlight-words'
import { Link as RouterLink } from 'react-router-dom'
import dayjs from 'dayjs'

import useIntervalResult from '@shared/hooks/src/useIntervalResult'
import { getThreadName, useThreadUsers } from '@shared/messaging/src/hooks'

import { AvatarGroup, Badge, Box, CardActionArea, Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

import { useThreadLabel } from './Thread.utils'

const styles = {
  container: {
    gap: 2,
    alignItems: 'center',
    p: 3,
    borderBottom: '1px solid',
    borderColor: 'primary.main',
  },
  group: {
    '.MuiAvatarGroup-avatar': {
      width: 32,
      height: 32,
    },
  },
}

export default function Thread({ data, highlight = '', newPageOnSelect = false }) {
  const users = useThreadUsers(data)
  const name = getThreadName(users)
  const label = useThreadLabel(users)

  return (
    <CardActionArea component={RouterLink} to={`/app/messages/${data.id}`} replace={!newPageOnSelect} data-testid={`thread-${name}`}>
      <Stack direction="row" sx={styles.container}>
        <AvatarGroup max={2} spacing="small" sx={styles.group}>
          {users.map((user) => (
            <Avatar key={user.id} user={user} size="sm" variant={users.length > 1 ? 'circular' : 'rounded'} />
          ))}
        </AvatarGroup>
        <Stack flexGrow={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Box sx={{ display: 'inline-grid' }}>
              <Typography noWrap>
                <Highlighter autoEscape searchWords={[highlight]} textToHighlight={name} />
              </Typography>
            </Box>
            {data.unreadMessageCount > 0 && <Badge color="primary" variant="dot" data-testid="has-unread" />}
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Typography variant="caption" color="text.secondary">
              {label}
            </Typography>
            <Timing date={data.latest} />
          </Stack>
        </Stack>
      </Stack>
    </CardActionArea>
  )
}

function Timing({ date }) {
  const passed = useIntervalResult(() => dayjs(date).fromNow())

  return (
    <Typography variant="caption" color="text.secondary">
      {passed}
    </Typography>
  )
}

Thread.Skeleton = function ThreadSkeleton() {
  return (
    <Stack direction="row" sx={styles.container}>
      <Skeleton variant="circular" width={32} height={32} />
      <Stack flexGrow={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography>
            <Skeleton width={200} />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography variant="caption" color="text.secondary">
            <Skeleton width={100} />
          </Typography>
          <Typography variant="caption" color="text.secondary">
            <Skeleton width={50} />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
