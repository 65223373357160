import React from 'react'
import PropTypes from 'prop-types'

import { useGTPageLoad } from '@utils/googleTags'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemText, Typography } from '@mui-components'

CountySelectionDialog.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** List of counties */
  counties: PropTypes.arrayOf(PropTypes.string),

  /** Called after any action or the dialog is closed */
  onCancel: PropTypes.func.isRequired,

  /** Called when selecting a county */
  onSelect: PropTypes.func.isRequired,
}

export default function CountySelectionDialog({ open, counties, onCancel, onSelect }) {
  const testId = 'country-selection-dialog'

  useGTPageLoad({ page: 'enroll_county', enabled: open })

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" PaperProps={{ 'data-testid': testId }}>
      <DialogTitle>Which county do you live in</DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Typography sx={{ px: 3, py: 1 }}>Your new location requires joining a new community partner that serves your area.</Typography>
        <List>
          {counties.map((county) => (
            <ListItemButton key={county} data-testid={`${testId}-county-${county}`} onClick={() => onSelect(county)}>
              <ListItemText>{county}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" data-testid={`${testId}-cancel`}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
