import { Button, Divider, Stack, Typography } from '@mui-components'

import { Property } from '../HealthHistory.utils'

export default function Immunization({ data, editAllowed = false, onEdit }) {
  const testId = `immunization-${data.name}`

  return (
    <Stack data-testid={testId}>
      <Divider sx={{ mb: 1 }} />
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Typography data-testid={`${testId}-name`}>{data.name}</Typography>
        {editAllowed && (
          <Button size="small" onClick={onEdit} data-testid={`${testId}-edit`}>
            Edit
          </Button>
        )}
      </Stack>
      <Property label="Year" value={data.year} data-testid={testId} />
    </Stack>
  )
}
