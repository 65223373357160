import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import { Link } from '@mui-components'
import LogoPride from '@assets/logo/logo-pride.png'

/**
 * Logo component
 *
 * @param isLink - if false the logo will not be a link
 * @param props - img props
 */
export default function Logo({ isLink = true, ...props }) {
  const theme = useTheme()

  const isPrideMonth = new Date().getMonth() === 5
  const content = (
    <img src={isPrideMonth ? LogoPride : theme.app.logo.large} alt="logo" style={{ maxHeight: 60, maxWidth: 200 }} {...props} />
  )

  if (isLink) {
    return (
      <Link component={RouterLink} to="/app">
        {content}
      </Link>
    )
  }

  return content
}
