import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useReadLocalStorage } from 'usehooks-ts'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import { useMe } from '@shared/providers/src/MeProvider'
import { AuthUtils, BuildEnv, getPatientFullName } from '@shared/utils'

import { useProfileMissingErrors } from '@pages/Profile'
import { LogoIcon, MenuIcon, ProvidedByQCareIcon } from '@icons'
import { AppBar, Badge, Box, Divider, IconButton, ListItemText, MenuItem, Popover, Stack, Toolbar, Typography } from '@mui-components'
import Avatar from '@components/Avatar'
import Dialog from '@components/Dialog'

import EditProfile from './EditProfile'
import Messages from './Messages'

export default function Header({ hideMenu = false, position = 'fixed' }) {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 })

  return (
    <>
      <AppBar elevation={trigger ? 6 : 0} position={position}>
        <Toolbar>
          <LogoIcon />
          <Box sx={{ flexGrow: 1 }} />
          {!hideMenu && <Menu />}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

function Menu() {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => setAnchorEl(null)

  return (
    <Wrapper anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <MenuHeader />
      <Divider />
      <Box sx={{ my: 1 }}>
        <EditProfile />
        <Messages />

        <Divider />

        <MenuItem component={Link} to="/app/labs" onClick={handleClose} data-testid="labs-menu-item">
          <ListItemText primary="Lab work" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MenuItem component={Link} to="/app/prescriptions" onClick={handleClose} data-testid="rx-menu-item">
          <ListItemText primary="Prescriptions" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MenuItem component={Link} to="/app/appointments" onClick={handleClose} data-testid="appointments-menu-item">
          <ListItemText primary="Appointments" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MenuItem component={Link} to="/app/documents" onClick={handleClose} data-testid="documents-menu-item">
          <ListItemText primary="Documents" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MenuItem component={Link} to="/app/assessments" onClick={handleClose} data-testid="assessments-menu-item">
          <ListItemText primary="Assessments" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>

        <Divider />
        <MenuItem id="faq-menu-item" component={Link} to="/app/faq" onClick={handleClose} data-testid="faq-menu-item">
          <ListItemText primary="FAQ" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MessageSupportItem />
        <Logout />

        {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && (
          <>
            <Divider />
            <MenuItem component="a" href={`${import.meta.env.VITE_URL}/environment`} onClick={handleClose} target="_blank" rel="noopener">
              <ListItemText primary="Environment (non-prod)" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
            </MenuItem>
          </>
        )}

        <Divider />
        <PoweredByQCare />
      </Box>
    </Wrapper>
  )
}

function Wrapper({ anchorEl, setAnchorEl, children }) {
  const me = useMe()
  const { data } = useProfileMissingErrors()
  const introTour = useReadLocalStorage('intro-tour')

  const notificationsCount = useMemo(() => {
    const profileMissingItemsCount = Object.values(data).reduce((sum, c) => sum + c, 0)
    return profileMissingItemsCount + me.totalUnreadMessageCount
  }, [data, me.totalUnreadMessageCount])

  useEffect(() => {
    // close the menu if the introduction tour has been passed
    if (introTour?.passed) {
      setAnchorEl(null)
    }
  }, [introTour?.passed, setAnchorEl])

  return (
    <>
      <IconButton
        id="main-menu-button"
        ref={anchorEl}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="large"
        color="inherit"
        edge="end"
        sx={{ zIndex: (theme) => theme.zIndex.appBar + 1 }}
        data-testid="main-menu-button"
      >
        <Badge color="error" badgeContent={notificationsCount} max={99} data-testid="notifications-count-badge">
          <MenuIcon fontSize="inherit" />
        </Badge>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          id: 'main-menu',
          // using pixel values, so we don't expand past a phone screen width
          sx: { maxWidth: 350 },
        }}
      >
        {children}
      </Popover>
    </>
  )
}

function MenuHeader() {
  const me = useMe()

  return (
    <Stack sx={{ p: 1, pt: 2, alignItems: 'center', wordBreak: 'break-word', textAlign: 'center' }}>
      <Avatar user={me} variant="rounded" sx={{ mb: 2 }} />
      <Typography color="textPrimary" variant="subtitle2" data-testid="menu-patient-fullName">
        {getPatientFullName(me)}
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" data-testid="menu-patient-email">
        {me.email}
      </Typography>
    </Stack>
  )
}

function MessageSupportItem() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <MenuItem onClick={() => setOpen(true)} data-testid="message-support-menu-item">
        <ListItemText primary="Contact support" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
      </MenuItem>
      <Dialog.MessageSupport open={open} onClose={() => setOpen(false)} />
    </>
  )
}

function Logout() {
  return (
    <MenuItem onClick={() => AuthUtils.logout()} data-testid="logout-menu-item">
      <ListItemText primary="Log out" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
    </MenuItem>
  )
}

function PoweredByQCare() {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1 }}
      component="a"
      href={import.meta.env.VITE_LANDING_PAGE}
      data-testid="landing-link"
    >
      <ProvidedByQCareIcon />
    </Box>
  )
}
