import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { range } from 'lodash'
import PropTypes from 'prop-types'

import { Box, FormControl, FormHelperText } from '@mui-components'

const allowedCharactersValues = ['alpha', 'numeric', 'alphanumeric']

const propsMap = {
  alpha: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}',
  },
  alphanumeric: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z0-9]{1}',
  },
  numeric: {
    type: 'tel',
    inputMode: 'numeric',
    pattern: '[0-9]{1}',
    min: '0',
    max: '9',
  },
}

const OneTimeCode = forwardRef((props, ref) => {
  const { error, allowedCharacters = 'numeric', loading, isPassword = false, length = 6, onChange } = props

  if (isNaN(length) || length < 1) {
    throw new Error('Length should be a number and greater than 0')
  }

  if (!allowedCharactersValues.some((value) => value === allowedCharacters)) {
    throw new Error('Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric')
  }

  const inputsRef = useRef([])
  const inputProps = propsMap[allowedCharacters]

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputsRef.current) {
        inputsRef.current[0].focus()
      }
    },
    clear: () => {
      if (inputsRef.current) {
        for (let i = 0; i < inputsRef.current.length; i++) {
          inputsRef.current[i].value = ''
        }
        inputsRef.current[0].focus()
      }
      sendResult()
    },
  }))

  useEffect(() => {
    inputsRef.current[0].focus()
  }, [])

  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join('')
    onChange && onChange(res)
  }

  const handleOnChange = (e) => {
    const {
      target: { value, nextElementSibling },
    } = e
    if (value.length > 1) {
      e.target.value = value.charAt(0)
      if (nextElementSibling !== null) {
        nextElementSibling.focus()
      }
    } else {
      if (value.match(inputProps.pattern)) {
        if (nextElementSibling !== null) {
          nextElementSibling.focus()
        }
      } else {
        e.target.value = ''
      }
    }
    sendResult()
  }

  const handleOnKeyDown = (e) => {
    const { key } = e
    const target = e.target
    if (key === 'Backspace') {
      if (target.value === '') {
        if (target.previousElementSibling !== null) {
          const t = target.previousElementSibling
          t.value = ''
          t.focus()
          e.preventDefault()
        }
      } else {
        target.value = ''
      }
      sendResult()
    }
  }

  const handleOnFocus = (e) => {
    e.target.select()
  }

  const handleOnPaste = (e) => {
    const pastedValue = e.clipboardData.getData('Text')

    let currentInput = 0

    for (let i = 0; i < pastedValue.length; i++) {
      const pastedCharacter = pastedValue.charAt(i)
      const currentValue = inputsRef.current[currentInput].value
      if (pastedCharacter.match(inputProps.pattern)) {
        if (!currentValue) {
          inputsRef.current[currentInput].value = pastedCharacter
          if (inputsRef.current[currentInput].nextElementSibling !== null) {
            inputsRef.current[currentInput].nextElementSibling.focus()
            currentInput++
          }
        }
      }
    }
    sendResult()

    e.preventDefault()
  }

  return (
    <Box>
      <FormControl>
        <Box sx={[styles, loading && { borderColor: 'action.disabled' }]} data-testid="one-time-code-inputs">
          {range(0, length).map((i) => (
            <input
              key={i}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onFocus={handleOnFocus}
              onPaste={handleOnPaste}
              {...inputProps}
              type={isPassword ? 'password' : inputProps.type}
              ref={(el) => {
                inputsRef.current[i] = el
              }}
              maxLength={1}
              autoComplete={i === 0 ? 'one-time-code' : 'off'}
              aria-label={`Character ${i + 1}.`}
              placeholder="_"
              disabled={loading}
            />
          ))}
        </Box>
        <FormHelperText error={Boolean(error)} sx={{ textAlign: 'center' }} data-testid="one-time-code-error">
          {error}
        </FormHelperText>
      </FormControl>
    </Box>
  )
})

OneTimeCode.propTypes = {
  /** Type of characters allowed in the field */
  allowedCharacters: PropTypes.oneOf(allowedCharactersValues),
  /** Error message to display */
  error: PropTypes.string,
  /** Whether the field is loading */
  loading: PropTypes.bool,
  /** Whether the field characters must be hidden */
  isPassword: PropTypes.bool,
  /** Count of characters to be filled */
  length: PropTypes.number,
  /** Callback to be called when the value changes */
  onChange: PropTypes.func.isRequired,
}

const styles = {
  display: 'flex',
  py: '5px',
  px: 2,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'primary.main',
  borderRadius: 1.5,
  gap: 1,
  '& input': {
    padding: 0,
    fontSize: 40,
    fontWeight: 700,
    width: 40,
    textAlign: 'center',
    color: 'text.primary',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
  '& input::placeholder': {
    color: 'text.primary',
    fontSize: 40,
    fontWeight: 300,
  },
  '& input:disabled': {
    color: 'text.disabled',
  },
}
export default OneTimeCode
