import { useState } from 'react'

import useDialog from '@shared/hooks/src/useDialog'
import { handleError } from '@shared/utils'

import { Button, Stack, Typography } from '@mui-components'
import Confirmation from '@components/Dialog/Confirmation'

import { useInsurance, useInsuranceRemove, useInsuranceUpdate } from './Insurance.hooks'
import Update from './Update'

export default function Insurance({ data }) {
  const testId = `insurance-card-${data.company}`
  const [open, setOpen] = useState(false)

  const { data: detailedInsurance } = useInsurance(data.id, { enabled: open })
  const update = useInsuranceUpdate(data.id)
  const remove = useInsuranceRemove(data.id)

  const confirmRemove = useDialog({
    component: Confirmation,
    props: ({ close }) => ({
      title: 'Remove Insurance?',
      description:
        'You may be required to keep active insurance by your community partner, and no insurance may result in issues filling your prescriptions.',
      rejectLabel: 'Cancel',
      confirmLabel: 'Remove',
      onReject: () => close(),
      onConfirm: () => remove.mutateAsync().then(close).catch(handleError),
    }),
  })

  return (
    <Stack spacing={1} sx={{ border: '1px solid', borderColor: 'primary.main', borderRadius: 2, mb: 2, py: 2, px: 3 }} data-testid={testId}>
      <Update
        open={Boolean(open && detailedInsurance)}
        insurance={detailedInsurance}
        onUpdate={update.mutateAsync}
        onClose={() => setOpen(false)}
      />
      <Typography variant="h6" fontWeight="bold" data-testid="company-field">
        {data.company}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Typography fontWeight="bold">ID</Typography>
        <Typography data-testid="idnum-field">{data.idnum}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" pt={1}>
        <Button variant="outlined" onClick={confirmRemove} data-testid={`${testId}-remove`}>
          Remove
        </Button>
        <Button variant="contained" disabled={open && !detailedInsurance} onClick={() => setOpen(true)} data-testid={`${testId}-update`}>
          Update
        </Button>
      </Stack>
    </Stack>
  )
}
