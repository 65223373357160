import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'

import Mask from '@shared/components/src/Mask'
import { useMe } from '@shared/providers/src/MeProvider'
import { handleError } from '@shared/utils'

import { Box, Button, Fade, Grid, LoadingButton, MenuItem, Stack, TextField, Typography, useMediaQuery } from '@mui-components'
import InputControl from '@components/InputControl'

import MissingInfo from '../components/MissingInfo'
import Title from '../components/Title'
import { usePatientUpdate } from '../Profile.hooks'
import { formikToApiData, getInitialValues, getMissingInfo, validationSchema } from './HealthAssistance.utils'

export default function HealthAssistanceForm({ onClose }) {
  const me = useMe()
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const update = usePatientUpdate()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(me),
    validationSchema,
    onSubmit: (values) =>
      update
        .mutateAsync(formikToApiData(values))
        .then(() => toast.success('Health Assistance information updated'))
        .then(onClose)
        .catch(handleError),
  })
  const missingInfo = useMemo(() => getMissingInfo(me), [me])

  return (
    <Fade in>
      <form noValidate onSubmit={formik.handleSubmit}>
        {isPhone && <Title label="Health Assistance" onClose={onClose} />}
        <MissingInfo data={missingInfo} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Typography variant="subtitle1">
            We use this information to determine if there are external programs to help fund your care.
          </Typography>
          <InputControl field="social" error={Boolean(missingInfo.social)} formikProps={formik}>
            <TextField label="Last 4 of Social Security Number" fullWidth required InputProps={{ inputComponent: Mask.SSN }} />
          </InputControl>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputControl field="income" formikProps={formik}>
                <TextField label="Household Income" fullWidth required={!me.insured} type="number" inputProps={{ min: 0 }} />
              </InputControl>
            </Grid>
            <Grid item xs={12}>
              <InputControl field="household" formikProps={formik}>
                <TextField label="Number of household members" fullWidth required={!me.insured} type="number" inputProps={{ min: 0 }} />
              </InputControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputControl field="medicare" formikProps={formik}>
                <TextField label="Medicaid" fullWidth select>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              </InputControl>
            </Grid>
            <Grid item xs={6}>
              <InputControl field="va" formikProps={formik}>
                <TextField label="Veteran" fullWidth select>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              </InputControl>
            </Grid>
            <Grid item xs={12}>
              <InputControl field="gilead" formikProps={formik}>
                <TextField label="Gilead AA#" fullWidth disabled />
              </InputControl>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => formik.resetForm()}
              disabled={!formik.dirty || formik.isSubmitting}
              variant="outlined"
              data-testid="health-assistance-cancel-btn"
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={!formik.dirty}
              loading={formik.isSubmitting}
              type="submit"
              variant="contained"
              data-testid="health-assistance-update-btn"
            >
              Update
            </LoadingButton>
          </Stack>
        </Box>
      </form>
    </Fade>
  )
}
