import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

import { getDropzoneData } from '@shared/utils'

import { List, ListItem } from '@mui-components'

FilesPreview.propTypes = {
  /** Can show preview of images */
  showPreview: PropTypes.bool,

  /** Array of files */
  files: PropTypes.array,
}

/**
 * Show preview of uploaded files.
 */
export default function FilesPreview({ files = [] }) {
  const hasFile = files.length > 0

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 0 }) }}>
      {files.map((file, index) => {
        const { key } = getDropzoneData(file, index)
        const preview = file instanceof File ? URL.createObjectURL(file) : file

        return (
          <ListItem key={key}>
            <ImgStyled
              src={preview}
              onLoad={() => {
                URL.revokeObjectURL(preview)
              }}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

const ImgStyled = styled('img')({
  display: 'block',
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '200px',
  marginLeft: 'auto',
  marginRight: 'auto',
})
