import useScrollTrigger from '@mui/material/useScrollTrigger'

import { LogoIcon } from '@icons'
import { AppBar, Toolbar } from '@mui-components'

export default function Header() {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 })

  return (
    <>
      <AppBar elevation={trigger ? 6 : 0}>
        <Toolbar>
          <LogoIcon isLink={false} />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}
