import { useReducer, useRef } from 'react'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import { FormControlLabel } from '@mui/material'

import AddressField from '@shared/components/src/AddressField'
import { useMe } from '@shared/providers/src/MeProvider'
import { AuthUtils, handleError, states } from '@shared/utils'

import { Box, Button, Checkbox, Fade, Grid, LoadingButton, MenuItem, Stack, TextField, Typography, useMediaQuery } from '@mui-components'
import Dialog from '@components/Dialog'
import InputControl from '@components/InputControl'

import Title from '../components/Title'
import { usePatientUpdate } from '../Profile.hooks'
import { useCBOs, useZipCodeInfo } from './Address.hooks'
import {
  Action,
  addressFormReducer,
  catchZipCodeError,
  DialogVariant,
  formikToApiData,
  getInitialValues,
  initialState,
  isValidationFailed,
  validationSchema,
} from './Address.utils'
import CBODialog from './CBODialog'
import CountySelectionDialog from './CountySelectionDialog'
import MedicareDialog from './MedicareDialog'
import NoCBODialog from './NoCBODialog'

export default function Address({ onClose }) {
  const me = useMe()
  const [state, dispatch] = useReducer(addressFormReducer, initialState)
  // Ref is required to resolve https://github.com/jaredpalmer/formik/issues/529
  const countyRef = useRef()
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const fetchZipCodeInfo = useZipCodeInfo()
  const fetchCBOs = useCBOs()
  const patientUpdate = usePatientUpdate()

  const cancelFlow = () => dispatch({ type: Action.Reset })

  const completeFlow = (additionalData = {}) => {
    return patientUpdate
      .mutateAsync({ ...formikToApiData(formik.values), ...additionalData })
      .catch(handleError)
      .finally(cancelFlow)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(me),
    validationSchema,
    onSubmit: async (values) => {
      dispatch({ type: Action.Processing })

      const validation = await Promise.all([
        fetchZipCodeInfo.mutateAsync(values.homeZip).catch(catchZipCodeError),
        values.homeAddressAsShipping ? Promise.resolve() : fetchZipCodeInfo.mutateAsync(values.shippingZip).catch(catchZipCodeError),
      ])

      const validationFailed = isValidationFailed(validation, formik)
      if (validationFailed) {
        return dispatch({ type: Action.Reset })
      }

      const isZipCodeTheSame = me.homeAddress.zip === values.homeZip
      if (isZipCodeTheSame) {
        return completeFlow().then(() => {
          toast.success('Addresses updated')
          onClose()
        })
      }

      const counties = validation[0]?.counties ?? []

      if (counties.length === 1) {
        return checkMedicare(counties[0])
      }

      dispatch({ type: Action.ShowCountySelection, counties })
    },
  })

  const checkMedicare = (county) => {
    formik.setFieldValue('homeCounty', county)
    countyRef.current = county

    const newStateIsCA = formik.values.homeState === 'CA'

    if (newStateIsCA) {
      dispatch({ type: Action.ShowMedicare })
    } else {
      checkCBOs()
    }
  }

  const checkCBOs = async () => {
    const zip = formik.values.homeZip
    const county = countyRef.current

    const cbos = await fetchCBOs.mutateAsync({ zip, county }).catch(handleError)

    const mineCBOSupportsNewZipAndCounty = cbos.some((cbo) => cbo.id === me.cbo?.id)

    if (mineCBOSupportsNewZipAndCounty) {
      await completeFlow().then(() => {
        toast.success('Addresses updated')
        onClose()
      })
    } else if (cbos.length > 0) {
      dispatch({ type: Action.ShowCBOSelection, cbos })
    } else {
      dispatch({ type: Action.ShowNoCBO })
    }
  }

  const selectCounty = async (county) => {
    dispatch({ type: Action.CloseDialog })
    checkMedicare(county)
  }

  const selectMedicare = async (medicare) => {
    dispatch({ type: Action.CloseDialog })
    await patientUpdate.mutateAsync({ patient_assistance: { medicare: medicare ? 1 : 0 } }).catch(handleError)
    checkCBOs()
  }

  const selectCBO = async (cbo) => {
    dispatch({ type: Action.CloseDialog })
    await completeFlow({ care_team: { cbo_id: cbo.id } }).then(() => {
      toast.success('Addresses and CBO updated')
      onClose()
    })
  }

  const notifyMe = async () => {
    dispatch({ type: Action.CloseDialog })
    await completeFlow({ subscribe: true }).then(() =>
      toast.success("We'll notify you when a community partner becomes available in your area")
    )
    setTimeout(() => AuthUtils.logout(), 3000)
  }

  return (
    <>
      <Fade in>
        <form noValidate onSubmit={formik.handleSubmit}>
          {isPhone && <Title label="Address" onClose={onClose} />}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Home Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <InputControl field="homeAddress" formikProps={formik}>
                  <AddressField
                    required
                    size="small"
                    label="Address"
                    onChange={({ address, city, state, county, zip }) => {
                      // Keep old entered values when not available
                      formik.setFieldValue('homeAddress', address)
                      if (city.length) formik.setFieldValue('homeCity', city)
                      if (state.length) formik.setFieldValue('homeState', state)
                      if (county.length) formik.setFieldValue('homeCounty', county)
                      if (zip.length) formik.setFieldValue('homeZip', zip)
                    }}
                  />
                </InputControl>
              </Grid>
              <Grid item xs={12}>
                <InputControl field="homeAddress2" formikProps={formik}>
                  <TextField label="Apt/Suite" fullWidth size="small" />
                </InputControl>
              </Grid>
              <Grid item xs={12}>
                <InputControl field="homeCity" formikProps={formik}>
                  <TextField label="City" fullWidth required size="small" />
                </InputControl>
              </Grid>
              <Grid item xs={4}>
                <InputControl field="homeState" formikProps={formik}>
                  <TextField select label="State" size="small" fullWidth required>
                    {states.map((s) => (
                      <MenuItem key={s} value={s}>
                        {s}
                      </MenuItem>
                    ))}
                  </TextField>
                </InputControl>
              </Grid>
              <Grid item xs={8}>
                <InputControl field="homeZip" formikProps={formik}>
                  <TextField label="Zip code" fullWidth required size="small" />
                </InputControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Shipping Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  name="homeAddressAsShipping"
                  label="Use home address for shipping"
                  onChange={formik.handleChange}
                  control={
                    <Checkbox
                      checked={formik.values.homeAddressAsShipping}
                      onClick={() => {
                        formik.setFieldValue('shippingAddress', '')
                        formik.setFieldValue('shippingAddress2', '')
                        formik.setFieldValue('shippingCity', '')
                        formik.setFieldValue('shippingState', '')
                        formik.setFieldValue('shippingZip', '')
                      }}
                      data-testid="home-address-as-shipping"
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputControl field="shippingAddress" formikProps={formik}>
                  <AddressField
                    label="Address"
                    size="small"
                    disabled={formik.values.homeAddressAsShipping}
                    required={!formik.values.homeAddressAsShipping}
                    onChange={({ address, city, state, county, zip }) => {
                      // Keep old entered values when not available
                      formik.setFieldValue('shippingAddress', address)
                      if (city.length) formik.setFieldValue('shippingCity', city)
                      if (state.length) formik.setFieldValue('shippingState', state)
                      if (county.length) formik.setFieldValue('shippingCounty', county)
                      if (zip.length) formik.setFieldValue('shippingZip', zip)
                    }}
                  />
                </InputControl>
              </Grid>
              <Grid item xs={12}>
                <InputControl field="shippingAddress2" formikProps={formik}>
                  <TextField disabled={formik.values.homeAddressAsShipping} label="Apt/Suite" fullWidth size="small" />
                </InputControl>
              </Grid>
              <Grid item xs={12}>
                <InputControl field="shippingCity" formikProps={formik}>
                  <TextField
                    disabled={formik.values.homeAddressAsShipping}
                    fullWidth
                    required={!formik.values.homeAddressAsShipping}
                    size="small"
                  />
                </InputControl>
              </Grid>
              <Grid item xs={4}>
                <InputControl field="shippingState" formikProps={formik}>
                  <TextField
                    select
                    size="small"
                    fullWidth
                    disabled={formik.values.homeAddressAsShipping}
                    required={!formik.values.homeAddressAsShipping}
                  >
                    {states.map((s) => (
                      <MenuItem key={s} value={s}>
                        {s}
                      </MenuItem>
                    ))}
                  </TextField>
                </InputControl>
              </Grid>
              <Grid item xs={8}>
                <InputControl field="shippingZip" formikProps={formik}>
                  <TextField
                    label="Zip code"
                    disabled={formik.values.homeAddressAsShipping}
                    fullWidth
                    required={!formik.values.homeAddressAsShipping}
                    size="small"
                  />
                </InputControl>
              </Grid>
            </Grid>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                onClick={() => formik.resetForm()}
                disabled={!formik.dirty || state.loading}
                variant="outlined"
                data-testid="address-cancel-btn"
              >
                Cancel
              </Button>
              <LoadingButton
                loading={state.loading}
                disabled={!formik.dirty}
                type="submit"
                variant="contained"
                data-testid="address-update-btn"
              >
                Update
              </LoadingButton>
            </Stack>
          </Box>
        </form>
      </Fade>

      <CountySelectionDialog
        open={state.dialog === DialogVariant.SelectCounty}
        counties={state.counties}
        onSelect={selectCounty}
        onCancel={cancelFlow}
      />
      <MedicareDialog
        open={state.dialog === DialogVariant.Medicare}
        onConfirm={() => selectMedicare(true)}
        onReject={() => selectMedicare(false)}
        onCancel={cancelFlow}
      />
      <CBODialog open={state.dialog === DialogVariant.SelectCBO} cbos={state.cbos} onSelect={selectCBO} onCancel={cancelFlow} />
      <NoCBODialog
        open={state.dialog === DialogVariant.NoCBO}
        onNotifyMe={notifyMe}
        onContactCustomerSupport={() => dispatch({ type: Action.ShowCustomerSupport })}
        onCancel={cancelFlow}
      />
      <Dialog.MessageSupport open={state.dialog === DialogVariant.CustomerSupport} onClose={cancelFlow} />
    </>
  )
}
