import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LoadingButton, Stack, Typography } from '@mui-components'

export default function ConfirmHIVCBOSupport({ loading, onCancel, onConfirm }) {
  const navigate = useNavigate()
  const handleConfirm = useCallback(
    () =>
      onConfirm().then(() => {
        navigate('/app/appointments?action=schedule-appointment')
      }),
    [navigate, onConfirm]
  )

  return (
    <Dialog open fullWidth maxWidth="xs" scroll="body">
      <DialogTitle>Enroll in HIV+ Care</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2">
            <b>Overview:</b>
            <br />
            For HIV positive patients, our providers are here to define and provide long-term care.
          </Typography>
          <Typography variant="body2">
            <b>Appointment Scheduling:</b>
            <br />
            You will schedule an on-line appointment with our Medical Assistant to start your HIV+ Care.
          </Typography>
          <Typography variant="body2">
            <b>Assessment:</b>
            <br />
            During your visit, you and the Medical Assistant will assess the suitability of the care program based on your health and
            insurance status.
          </Typography>
          <Typography variant="body2">
            <b>If Seeking Prevention:</b>
            <br />
            If you are not HIV positive and are looking for prevention, please go back and choose PrEP option.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          <LoadingButton onClick={handleConfirm} variant="contained" loading={loading} data-testid="hiv-confirm">
            Schedule Appointment
          </LoadingButton>
          <Button onClick={onCancel} disabled={loading} data-testid="back">
            Go Back
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
