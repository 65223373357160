import { useState } from 'react'

import { useMe } from '@shared/providers/src/MeProvider'

import { AddIcon } from '@icons'
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui-components'
import Insurance from '@components/Card/Insurance'
import Update from '@components/Card/Insurance/Update'
import RenderControl from '@components/RenderControl'
import Transitions from '@components/Transitions'

import MissingInfo from '../components/MissingInfo'
import Title from '../components/Title'
import { useInsuranceCreation, useInsurances } from './Insurance.hooks'
import { getMissingInfo } from './Insurance.utils'

export default function Insurances({ onClose }) {
  const me = useMe()
  const [open, setOpen] = useState(false)
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { data, isPending } = useInsurances()
  const create = useInsuranceCreation()

  const isEmpty = !isPending && data?.length === 0

  const missingInfo = getMissingInfo(me, data?.length)

  return (
    <Stack>
      {isPhone && <Title label="Insurance" onClose={onClose} />}
      <MissingInfo data={missingInfo} />
      <RenderControl isLoading={isPending} isEmpty={isEmpty} emptyTitle={<Empty />}>
        <Transitions type="fade" in>
          {data?.map((insurance) => (
            <Insurance key={insurance.id} data={insurance} />
          ))}
        </Transitions>
      </RenderControl>
      <Box sx={{ py: 2, margin: '0 auto' }}>
        <Button startIcon={<AddIcon />} variant="contained" onClick={() => setOpen(true)} data-testid="new-insurance">
          Add new insurance
        </Button>
      </Box>
      <Update onUpdate={create.mutateAsync} open={open} onClose={() => setOpen(false)} />
    </Stack>
  )
}

function Empty() {
  return (
    <Typography variant="h6" align="center" data-testid="no-insurances">
      You haven’t added any insurance yet.
    </Typography>
  )
}
