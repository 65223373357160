import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Logger } from '@shared/utils'

import { Box, CircularProgress, Stack, Typography } from '@mui-components'
import Transitions from '@components/Transitions'

const log = Logger('RenderControl.js')

RenderControl.propTypes = {
  /** Display the loading state */
  isLoading: PropTypes.bool,

  /** Display empty state */
  isEmpty: PropTypes.bool,

  /** Option title to display when isEmpty is true */
  emptyTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** Can override the color of the spinner, provider a valid Mui color */
  progressColor: PropTypes.string,

  /** Height of the loading or empty state */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Children */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

/**
 * A wrapper to manage the display of loading and empty states for async data.
 *
 * @example
 * const {data, isLoading, error} = useQuery(...)
 * return (
 *   <RenderControl isLoading={isLoading} isEmpty={data?.length === 0} emptyTitle="No items to display">
 *     {data?.map((item) => <p>{item.name}</p>)}
 *   </RenderControl>
 */
export default function RenderControl({
  isLoading = false,
  isEmpty = false,
  emptyTitle = '',
  progressColor = 'primary',
  height = '100%',
  children,
}) {
  const content = useMemo(() => {
    if (isLoading && isEmpty) {
      log.warn('Component received true for both loading and isEmpty. This is not supported.')
    }

    if (isEmpty) return <Empty show title={emptyTitle} height={height} />
    if (isLoading) return <Spinner loading={true} progressColor={progressColor} height={height} />
    return children
  }, [children, emptyTitle, height, isEmpty, isLoading, progressColor])

  return <Box sx={{ height: '100%', width: '100%', minHeight: 100, position: 'relative' }}>{content}</Box>
}

function Wrap({ height, children }) {
  return (
    <Box sx={{ height }}>
      <Stack
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Stack>
    </Box>
  )
}

function Empty({ show = false, title = 'No data', height = '100%', ...rest }) {
  return (
    <Transitions type="fade" in={show} unmountOnExit>
      <Wrap height={height}>
        {typeof title === 'string' ? <Typography variant="h6" color="secondary" {...rest} children={title} /> : title}
      </Wrap>
    </Transitions>
  )
}

function Spinner({ loading = false, progressColor, height = '100%', ...rest }) {
  return (
    <Transitions type="fade" in={loading} unmountOnExit>
      <Wrap height={height}>
        <CircularProgress color={progressColor} {...rest} />
      </Wrap>
    </Transitions>
  )
}
