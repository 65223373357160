import PropTypes from 'prop-types'

import { getTestId } from '@shared/utils'

import { Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

User.propTypes = {
  data: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    pronouns: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profilePictureThumbUrl: PropTypes.string,
  }).isRequired,

  /** Can display on a dark background */
  contrast: PropTypes.bool,
}

/**
 * Component to display User's data.
 * @note Modify and extend according to your needs
 *
 * @example <User data={user} />
 */
export default function User({ data, contrast = false, ...rest }) {
  const testId = getTestId(rest, 'user')

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar user={data} data-testid={`${testId}-avatar`} />
      <Stack>
        <Typography color={contrast ? 'primary.contrastText' : 'text.primary'} data-testid={`${testId}-fullName`}>
          {data.fullName}
        </Typography>
        <Typography color={contrast ? 'primary.contrastTextSecondary' : 'text.secondary'} data-testid={`${testId}-pronouns`}>
          {data.pronouns}
        </Typography>
      </Stack>
    </Stack>
  )
}
