import useTheme from '@mui/styles/useTheme'

import { Button, Fade, Grid, Stack, Typography, useMediaQuery } from '@mui-components'

export default function ErrorBase() {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fade in>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', p: 1, overflow: 'hidden' }}
      >
        <Grid item xs={12}>
          <Stack spacing={6} justifyContent="center" alignItems="center">
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography variant={matchDownSM ? 'h2' : 'h1'} align="center">
                Translation Unavailable
              </Typography>
              <Typography variant="h5" align="center">
                We regret to inform you that a technical issue is currently preventing us from fully supporting the Translation feature.
                <br />
                <br />
                To ensure a seamless experience, we recommend temporarily disabling it.
                <br />
                <br />
                We apologize for any inconvenience this may cause and appreciate your understanding as we actively work to resolve this
                issue.
                <br />
                <br />
                Thank you for your patience.
              </Typography>
            </Stack>
            <Button variant="contained" onClick={() => window.location.reload()}>
              Reload
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fade>
  )
}
