import React, { useState } from 'react'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'

import { useMe } from '@shared/providers/src/MeProvider'
import { handleError, toBase64 } from '@shared/utils'

import { Box, Button, Dialog, DialogActions, DialogContent, Stack } from '@mui-components'
import FileUploader from '@components/FileUploader'
import ImageCrop from '@components/ImageCrop'

import { usePatientDeleteProfilePic, usePatientUpdateProfilePic } from './ProfilePic.hooks'

ProfilePicDialog.propTypes = {
  /** Open dialog */
  open: PropTypes.bool.isRequired,

  /**
   * Called after the close action
   */
  onClose: PropTypes.func.isRequired,
}

export default function ProfilePicDialog({ open, onClose }) {
  const testId = 'update-profile-pic-dialog'

  const [profilePic, setProfilePic] = useState(null)
  const [openImageCropper, setOpenImageCropper] = useState(false)

  // Use context to get if patient has a profile pic set
  const { profilePictureUrl } = useMe()

  const upload = usePatientUpdateProfilePic()
  const remove = usePatientDeleteProfilePic()

  const handleRemovingExistingImage = () => {
    remove
      .mutateAsync()
      .then(() => toast.success('Profile picture removed'))
      .catch(handleError)

    onClose()
  }

  const handleSelectingProfilePic = async (files) => {
    if (files.length) {
      const base64 = await toBase64(files[0])
      setProfilePic(base64)
      setOpenImageCropper(true)
    }
  }

  const handleCancelCroppingImage = () => {
    setOpenImageCropper(false)
  }

  const handleSavingImage = (base64) => {
    // Cropper returns always jpeg
    setOpenImageCropper(false)

    // Upload profile picture
    upload
      .mutateAsync({ mime_type: 'image/jpeg', base_64_image: base64.split(',')[1] })
      .then(() => toast.success('Profile picture updated'))
      .catch((error) => handleError(error, { showResponse: true }))

    onClose()
  }

  return (
    <>
      <Dialog open={open && !openImageCropper} maxWidth="md" PaperProps={{ sx: { m: 3, px: 2, pt: 3, pb: 2 }, 'data-testid': testId }}>
        <DialogContent sx={{ p: 0 }}>
          <Stack direction="column" spacing={0} alignItems="center">
            <FileUploader
              files={[]}
              onChange={handleSelectingProfilePic}
              allowedDescription="PNG or JPG"
              dropzoneOptions={{
                accept: {
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg', '.jpg'],
                },
              }}
            />
            {Boolean(profilePictureUrl) && (
              <Box sx={{ py: 2, px: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleRemovingExistingImage} color="error">
                  Remove Image
                </Button>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', px: 0 }}>
          <Button onClick={onClose} data-testid={`${testId}-cancel`}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openImageCropper && (
        <ImageCrop file={profilePic} cropType="square" onSave={handleSavingImage} closeOnSave onCancel={handleCancelCroppingImage} />
      )}
    </>
  )
}
