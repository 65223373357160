import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { getTestId, toTitleCase } from '@shared/utils'

import { ArrowDropDownIcon, ArrowDropUpIcon } from '@icons'
import { Button, Collapse, IconButton, Skeleton, Stack, Typography } from '@mui-components'

export const styles = {
  card: {
    p: 2,
    minHeight: 78,
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'primary.main',
  },
}

export const Property = ({ label, value, ...rest }) => {
  const testId = getTestId(rest, 'property')

  return (
    <Stack direction="row" data-testid={`${testId}-property`}>
      <Typography variant="caption" color="text.secondary" sx={{ minWidth: 70 }} data-testid={`${testId}-property-${label}`}>
        {label}
      </Typography>
      <Typography variant="body2" noWrap data-testid={`${testId}-property-${label}-${value}`}>
        {value || '-'}
      </Typography>
    </Stack>
  )
}

export function BaseCard({ items = [], hasMore, name, onAdd, children }) {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const testId = `card-${name}`

  const count = items.length
  const lastUpdatedItem = useMemo(() => {
    return items.reduce((oldestItem, currentItem) => {
      if (Date.parse(currentItem.updatedAt) > Date.parse(oldestItem.updatedAt)) {
        return currentItem
      } else {
        return oldestItem
      }
    }, items[0])
  }, [items])

  if (items.length === 0) return <Empty name={name} onAdd={onAdd} />

  return (
    <Stack sx={styles.card} data-testid={testId}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Stack>
          <Stack direction="row" gap={1}>
            <Typography data-testid={`${testId}-title`}>{toTitleCase(name)}</Typography>
            <Typography data-testid={`${testId}-count`}>{`(${count}${hasMore ? '+' : ''})`}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Last updated:
            </Typography>
            <Typography variant="caption" data-testid={`${testId}-last-updated`}>
              {lastUpdatedItem ? dayjs(lastUpdatedItem.updatedAt).format('LL') : '-'}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Button size="small" onClick={onAdd} data-testid={`${testId}-new`}>
            Add
          </Button>
          <IconButton size="small" onClick={() => setIsCollapsed((prev) => !prev)} data-testid={`${testId}-collapse-toggle`}>
            {isCollapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </Stack>
      </Stack>
      <Collapse in={isCollapsed}>
        <Stack spacing={1} pt={1}>
          {children}
        </Stack>
      </Collapse>
    </Stack>
  )
}

BaseCard.Loading = () => <Skeleton variant="rounded" height={78} />

const Empty = ({ name, onAdd }) => (
  <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={styles.card} data-testid={`empty-${name}`}>
    <Typography>
      You don’t have any <b>{name}</b>
    </Typography>
    <Button size="small" onClick={onAdd} data-testid={`empty-${name}-add`}>
      Add
    </Button>
  </Stack>
)
