import { Outlet } from 'react-router-dom'

import Transitions from '@components/Transitions'

import AuthorizedLayout from './AuthorizedLayout'
import GuestLayout from './GuestLayout'

export default function Layout({ guest = false, children }) {
  const LayoutComponent = guest ? GuestLayout : AuthorizedLayout

  return (
    <LayoutComponent>
      <Transitions in type="fade">
        {children || <Outlet />}
      </Transitions>
    </LayoutComponent>
  )
}
