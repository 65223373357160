import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LoadingButton, Stack, Typography } from '@mui-components'

export default function ConfirmOralPrEP({ loading, onCancel, onConfirm }) {
  const navigate = useNavigate()
  const handleConfirm = useCallback(
    () =>
      onConfirm().then(() => {
        navigate('/app/appointments?action=schedule-appointment')
      }),
    [navigate, onConfirm]
  )

  return (
    <Dialog open fullWidth maxWidth="xs" scroll="body">
      <DialogTitle>Enroll in Oral PrEP Care</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography fontWeight="bold">PrEP is a medication used to help prevent HIV infections.</Typography>
          <Typography variant="body2">
            <b>Overview:</b>
            <br />
            Initiate your PrEP care with an online provider appointment to assess suitability based on your health and insurance status.
          </Typography>
          <Typography variant="body2">
            <b>Ongoing Care:</b>
            <br />
            Upon approval, follow the prescribed schedule for Oral PrEP. Adhering to this schedule is crucial for optimal effectiveness.
          </Typography>
          <Typography variant="body2">
            <b>Adherence is Key:</b>
            <br />
            Commit to the prescribed schedule. Regular provider follow-ups are essential for monitoring and maintaining your health.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          <LoadingButton onClick={handleConfirm} variant="contained" loading={loading} data-testid="oral-prep-confirm">
            Schedule Appointment
          </LoadingButton>
          <Button onClick={onCancel} disabled={loading} data-testid="back">
            Go Back
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
