import React from 'react'
import { Link } from 'react-router-dom'

import { useMe } from '@shared/providers/src/MeProvider'

import { Badge, Drawer, ListItemText, MenuItem, Stack, useMediaQuery } from '@mui-components'
import FormHeader from '@components/Header/Form'
import Threads from '@components/Threads'

import { useDrawer } from '../Header.hooks'

export default function Messages() {
  const me = useMe()
  const [drawer, setDrawer] = useDrawer()
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true })

  return (
    <>
      {mdDown && <MessagesList open={drawer === 'messages'} onClose={() => setDrawer(undefined)} />}
      <MenuItem
        {...(mdDown ? { onClick: () => setDrawer('messages') } : { component: Link, to: '/app/messages' })}
        data-testid="messages-menu-item"
      >
        <ListItemText primary="Messages" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        <Badge color="primary" badgeContent={me.totalUnreadMessageCount} max={99} data-testid="unread-messages-count-badge" />
      </MenuItem>
    </>
  )
}

function MessagesList({ open, onClose }) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant="temporary"
      anchor="left"
      sx={{ zIndex: 1300, '& .MuiDrawer-paper': { maxWidth: 320, width: '100%' } }}
      PaperProps={{ 'data-testid': 'messages-drawer' }}
    >
      <Stack>
        <FormHeader title="Messages" onClose={onClose} />
        <Threads newPageOnSelect />
      </Stack>
    </Drawer>
  )
}
