/* eslint-disable react/forbid-foreign-prop-types */
import React, { useMemo } from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { camelToTitleCase } from '@shared/utils'

InputControl.propTypes = {
  /** An input/control component to render */
  children: PropTypes.node.isRequired,

  /** The property name used to provide data to the component */
  field: PropTypes.string.isRequired,

  /** Override the error indicator from outside */
  error: PropTypes.bool,

  /** The formik props that the control will use */
  formikProps: PropTypes.shape({
    values: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
  }).isRequired,
}

/**
 * A wrapper/helper to pass the typical formik props to a component.
 *
 * This will inject a number of properties into the child component that are used
 * by formik to provide the standard behavior. The "field" value will be used for
 * the id, name, label, data-testid.
 *
 * @example
 * const { values, handleChange, handleBlur, touched, errors } = useFormik({
 *    const formikProps = { values, touched, errors, handleChange, handleBlur }
 *
 *    return (
 *      <InputControl field="doctorName" formikProps={formikProps}>
 *        <TextField required />
 *      </InputControl>
 *  )
 * })
 */
export default function InputControl({ children, field, formikProps, error, ...rest }) {
  // Pull any known props from the child for re-use
  const childRequired = children.props.required || false
  const childLabel = children.props.label
  const childOnChange = children.props.onChange
  const childOnBlur = children.props.onBlur
  const childHelperText = children.props.helperText
  const childSize = children.props.size || 'small'

  const hasHelperText = children.type.propTypes?.hasOwnProperty('FormHelperTextProps')

  // Calculate the label 'type'
  const label = useMemo(() => {
    // If label not provided, use the field name
    if (!childLabel) {
      return `${camelToTitleCase(field)}`
    }
    // If label is provided, and it is a string type, use it and extend with optional if not required
    if (typeof childLabel === 'string') {
      return `${childLabel}`
    }
    // If label is provided, and it is not a string type, assume it is a component and return it
    return childLabel
  }, [childLabel, field])

  const handleBlur = (e) => {
    // handle cases where the child component has an onBlur handler and we want to combine with formik's
    if (childOnBlur) childOnBlur(e)
    formikProps.handleBlur(e)
  }

  const additionalProps = {
    required: childRequired,
    variant: 'outlined',
    id: field,
    name: field,
    value: get(formikProps.values, field),
    label,
    size: childSize,
    onChange: childOnChange ?? formikProps.handleChange,
    onBlur: handleBlur,
    error: error || (get(formikProps.touched, field) && Boolean(get(formikProps.errors, field))),
    helperText: (get(formikProps.touched, field) && get(formikProps.errors, field)) || childHelperText,
    inputProps: { 'data-testid': `input-${field}`, required: false, ...children.props.inputProps },
    ...(hasHelperText && { FormHelperTextProps: { 'data-testid': `helper-${field}` } }),
    ...rest,
  }

  return React.cloneElement(children, additionalProps)
}
