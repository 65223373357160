import { useMemo } from 'react'
import { FormProvider, useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import SettingsSharedApi from '@shared/services/src/Settings.api.js'
import { handleError, includesOneOfErrorMessages, UserSettingName } from '@shared/utils'

import { useConsents } from '@hooks'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Link,
  LoadingButton,
  Stack,
  TextField,
  Typography,
} from '@mui-components'
import HookInputControl from '@components/HookInputControl'
import { appName } from '@config'

import { useGiveConsent } from './ConsentDialog.hooks'

export default function ConsentDialog() {
  const consents = useConsents()
  const giveConsent = useGiveConsent()

  const noInitials = !consents[UserSettingName.Initials]
  const noHealthDataSharing = !consents[UserSettingName.AllowSharingHealthData]

  const noTOS = !consents[UserSettingName.AcceptTermsOfService]
  const noPrivacyPolicy = !consents[UserSettingName.AcceptPrivacyPolicy]
  const noTelehealthConsent = !consents[UserSettingName.AcceptTelehealthConsent]

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(
      Yup.object().shape({
        ...(noInitials && {
          initials: Yup.string().required('Enter your initials'),
        }),
        ...(noHealthDataSharing && {
          sharing_data: Yup.boolean().required('Agree to sharing your information').oneOf([true], 'Agree to sharing your information'),
        }),
        ...((noTOS || noPrivacyPolicy || noTelehealthConsent) && {
          agree: Yup.boolean().required('Agree Policy').oneOf([true], 'Agree Policy'),
        }),
      })
    ),
    defaultValues: {
      ...(noInitials && { initials: '' }),
      ...(noHealthDataSharing && { sharing_data: false }),
      ...((noTOS || noPrivacyPolicy || noTelehealthConsent) && { agree: false }),
    },
  })

  const onSubmit = (values) => {
    const data = {
      ...(noInitials && { initials: values.initials }),
      ...(noHealthDataSharing && { sharing_data: values.sharing_data }),
      ...(noTOS && { agree_tos: values.agree }),
      ...(noPrivacyPolicy && { agree_privacy_policy: values.agree }),
      ...(noTelehealthConsent && { telehealth_consent: values.agree }),
    }

    return giveConsent.mutateAsync(data).catch((response) => {
      // Invalid initials
      if (includesOneOfErrorMessages(response.error, ['initials'])) {
        return form.setError('initials', { message: 'Please enter your initials' })
      }
      handleError(response)
    })
  }

  return (
    <Dialog open fullWidth maxWidth="sm">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={4} direction="column">
              {noInitials && <Consent />}
              {noHealthDataSharing && <DataSharing />}
              {(noTOS || noPrivacyPolicy || noTelehealthConsent) && <TOS consents={consents} />}
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton variant="contained" type="submit" loading={form.formState.isSubmitting} disabled={!form.formState.isDirty}>
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

function Consent() {
  return (
    <Stack spacing={1} alignItems="start">
      <Typography variant="h6">Consent to Treat</Typography>
      <Typography>We require all patients to confirm their Consent to Treatment by {appName} providers.</Typography>
      <Typography>Initial below to give your consent.</Typography>
      <HookInputControl field="initials">
        <TextField label="Initials" placeholder="Initials" inputProps={{ size: 4 }} />
      </HookInputControl>
    </Stack>
  )
}

function DataSharing() {
  const { cbo } = useMe()
  const { field, fieldState } = useController({ name: 'sharing_data' })

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Data Sharing</Typography>
      <Typography>{cbo?.name}, our community partner, uses your information to waive your provider and lab fees.</Typography>
      <Typography variant="body2">Some insurance providers may restrict pharmacy choices which can impact participation.</Typography>
      <Stack>
        <FormControlLabel
          name="sharing_data"
          label={`I agree to share my information with the ${cbo?.name}`}
          control={<Checkbox checked={field.value} onChange={field.onChange} />}
        />
        {fieldState.error && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
      </Stack>
    </Stack>
  )
}

function TOS({ consents }) {
  const { field, fieldState } = useController({ name: 'agree' })

  const label = useMemo(() => {
    const noTOS = !consents[UserSettingName.AcceptTermsOfService]
    const noPrivacyPolicy = !consents[UserSettingName.AcceptPrivacyPolicy]
    const noTelehealthConsent = !consents[UserSettingName.AcceptTelehealthConsent]

    const count = [noTOS, noPrivacyPolicy, noTelehealthConsent].filter(Boolean).length
    const title = [noTelehealthConsent && 'Telehealth Consent', noPrivacyPolicy && 'Privacy Policy', noTOS && 'Terms of Service'].filter(
      Boolean
    )

    return `Our ${joinStrings(title)} ${count > 1 ? 'have' : 'has'} been updated. Please review and accept to continue.`
  }, [consents])

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Policy Updates</Typography>
      <Typography>{label}</Typography>
      <Stack>
        <FormControlLabel label={<TOSLabel consents={consents} />} control={<Checkbox checked={field.value} onChange={field.onChange} />} />
        {fieldState.error && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
      </Stack>
    </Stack>
  )
}

function TOSLabel({ consents }) {
  const { data } = useQuery({
    queryKey: ['consent-documents'],
    queryFn: SettingsSharedApi.consentDocuments,
  })

  const content = useMemo(() => {
    const noTOS = !consents[UserSettingName.AcceptTermsOfService]
    const noPrivacyPolicy = !consents[UserSettingName.AcceptPrivacyPolicy]
    const noTelehealthConsent = !consents[UserSettingName.AcceptTelehealthConsent]

    const content = [
      noTelehealthConsent && (
        <Link component="a" href={data?.['telehealth_consent'] || '#'} target="_blank" rel="noopener noreferrer">
          Telehealth&nbsp;Consent
        </Link>
      ),
      noPrivacyPolicy && (
        <Link component="a" href={data?.['agree_privacy_policy'] || '#'} target="_blank" rel="noopener noreferrer">
          Privacy&nbsp;Policy
        </Link>
      ),
      noTOS && (
        <Link component="a" href={data?.['agree_tos'] || '#'} target="_blank" rel="noopener noreferrer">
          Terms&nbsp;of&nbsp;Service
        </Link>
      ),
    ].filter(Boolean)

    return joinNodes(content)
  }, [consents, data])

  return (
    <Typography variant="body2">
      I accept the {appName} {content}
    </Typography>
  )
}

function joinStrings(arr) {
  if (arr.length === 1) return arr[0]
  const firsts = arr.slice(0, arr.length - 1)
  const last = arr[arr.length - 1]
  return firsts.join(', ') + ' and ' + last
}

function joinNodes(nodes) {
  return nodes.map((node, index) => {
    if (index === nodes.length - 2) {
      // This is the second-to-last node
      return <span key={index}>{node} and </span>
    } else if (index < nodes.length - 2) {
      // This is not the last or second-to-last node
      return <span key={index}>{node}, </span>
    } else {
      // This is the last node
      return <span key={index}>{node}</span>
    }
  })
}
