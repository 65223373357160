import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Avatar as AvatarMui } from '@mui-components'

import { getSizeStyle, stringAvatar } from './Avatar.utils'

Avatar.propTypes = {
  /** The user to display in the avatar */
  user: PropTypes.shape({
    nickname: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePictureThumbUrl: PropTypes.string,
  }),

  /** Can override the variant, default is 'rounded' */
  variant: PropTypes.oneOf(['circular', 'rounded', 'square']),

  /** Size of Avatar, default is 'md' */
  size: PropTypes.oneOf(['badge', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
}

/**
 * Display a users avatar. If a profile image is available, it will be displayed. Otherwise, the users initials will be displayed.
 */
export default function Avatar({ user, variant = 'rounded', size = 'md', sx, ...rest }) {
  const { backgroundColor, initials } = useMemo(() => stringAvatar(user), [user])

  return (
    <AvatarMui
      alt="profile picture"
      src={user?.profilePictureThumbUrl}
      variant={variant}
      size={size}
      sx={[getSizeStyle(size), { color: 'primary.contrastText', backgroundColor }, sx]}
      {...rest}
    >
      {initials}
    </AvatarMui>
  )
}
