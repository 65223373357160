import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment, TextField } from '@mui/material'

import { getTestId } from '@shared/utils'

import { CloseIcon, SearchIcon } from '@icons'

SearchInput.propTypes = {
  /** The value for the input */
  value: PropTypes.string,

  /** Can display an optional label */
  label: PropTypes.string,

  /** Issue onChange after enter key is pressed */
  searchOnEnter: PropTypes.bool,

  /** Called after the search action */
  onChange: PropTypes.func.isRequired,
}

/**
 * Stateless SearchInput Component which provides 'clear' input possibility
 */
export default function SearchInput({ value = '', label = 'Search', onChange, searchOnEnter = false, disabled = false, ...rest }) {
  const testId = getTestId(rest, 'search-input')

  const [savedValue, setSavedValue] = useState(value)

  const handleChange = useCallback(
    (value) => {
      if (searchOnEnter) {
        setSavedValue(value)
        return
      }

      onChange(value)
    },
    [onChange, searchOnEnter]
  )

  const handleKeyUp = (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      onChange(savedValue)
    }
  }

  const handleClear = () => {
    onChange('')
    setSavedValue('')
  }

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      value={searchOnEnter ? savedValue : value}
      label={label}
      onChange={(event) => handleChange(event.target.value)}
      onKeyUp={handleKeyUp}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value || savedValue ? (
              <IconButton
                color="inherit"
                disabled={disabled}
                size="small"
                onClick={handleClear}
                sx={{ p: 0 }}
                data-testid={`${testId}-clear`}
              >
                <CloseIcon color="inherit" />
              </IconButton>
            ) : (
              <SearchIcon color="inherit" />
            )}
          </InputAdornment>
        ),
      }}
      inputProps={{ 'data-testid': testId }}
      {...rest}
    />
  )
}
