import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemText, Typography } from '@mui-components'

CBODialog.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** List of CBOs to display */
  cbos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),

  /** Called after any action or the dialog is closed*/
  onCancel: PropTypes.func.isRequired,

  /** Called when selecting a CBO */
  onSelect: PropTypes.func.isRequired,
}

export default function CBODialog({ open, cbos = [], onCancel, onSelect }) {
  const testId = 'cbo-selection-dialog'

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" PaperProps={{ 'data-testid': testId }}>
      <DialogTitle>Choose Community Partner</DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Typography sx={{ px: 3, py: 1 }}>Your new location requires joining a new community partner that serves your area.</Typography>
        <List>
          {cbos.map((cbo, index) => (
            <ListItemButton key={cbo.id} data-testid={`${testId}-cbo-${index}`} onClick={() => onSelect(cbo)}>
              <ListItemText>{cbo.name}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" data-testid={`${testId}-cancel`}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
