import { useState } from 'react'
import debounce from 'lodash/debounce'
import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import useGooglePlacesAutocomplete from '@shared/hooks/src/useGooglePlacesAutocomplete'
import { Logger } from '@shared/utils'

const log = Logger('AddressField.js')

/** Wrapper around MUI Autocomplete to handle address suggestions */
export default function AddressField({ onChange, helperText, error, inputProps, autoFocus = false, ...rest }) {
  const [addressOptions, setAddressOptions] = useState([])

  const { getAddressSuggestions, getPlaceDetails } = useGooglePlacesAutocomplete()

  // Typing autocomplete google search
  const handleTypingAddress = debounce((newValue) => {
    getAddressSuggestions(newValue).then((results) => {
      setAddressOptions(newValue.length ? [newValue, ...(results ?? [])] : results ?? [])
    })
  }, 250)

  // Selecting address in autocomplete
  const handleSelectingAddress = (type, option) => {
    if (!option?.place_id) {
      return onChange({ address: option, city: '', state: '', county: '', zip: '' })
    }

    getPlaceDetails(option.place_id)
      .then(({ address1: address, city, state, county, postcode }) => {
        const zip = postcode.split('-')[0] || ''
        onChange({ address, city, state, county, zip })
      })
      .catch((e) => log.warn(`getPlaceDetails ${option.placeId}`, e))
  }

  return (
    <Autocomplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      autoComplete
      disableClearable
      forcePopupIcon
      freeSolo
      selectOnFocus={false}
      noOptionsText="No matches"
      options={addressOptions}
      filterOptions={(x) => x}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      onChange={(event, option) => handleSelectingAddress('home', option)}
      onInputChange={(event, value) => handleTypingAddress(value)}
      renderOption={({ key, ...props }, option) => {
        const isGooglePlace = option.place_id
        return (
          <li key={`${key}${isGooglePlace ? '' : '_input'}`} {...props}>
            {isGooglePlace ? (
              <Stack>
                <Typography>{option.structured_formatting.main_text}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Stack>
            ) : (
              <Typography variant="body2">{option}</Typography>
            )}
          </li>
        )
      }}
      renderInput={(params) => {
        if (!params?.label && rest?.label) {
          params.label = rest.label
        }

        if (!params?.size && rest?.size) {
          params.size = rest.size
        }

        return (
          <TextField
            inputProps={inputProps}
            helperText={helperText}
            error={error}
            {...params}
            autoFocus={autoFocus}
            required={Boolean(rest.required)}
          />
        )
      }}
      {...rest}
    />
  )
}
