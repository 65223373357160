import { toast } from 'react-hot-toast'
import { EditorContent, useEditor } from '@tiptap/react'
import PropTypes from 'prop-types'

import { useSendMessage } from '@shared/messaging/src/hooks'
import { clipboardTextParser, richTextStyles } from '@shared/messaging/src/RichTextHelper'
import { useMe } from '@shared/providers/src/MeProvider'
import { getTestId } from '@shared/utils'

import { useUserOutOfOfficeListener } from '@hooks'
import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LoadingButton, Stack } from '@mui-components'
import { extensions } from '@components/RichText'
import User from '@components/User'

MessageCareTeam.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** Called after any action or the dialog is closed*/
  onClose: PropTypes.func.isRequired,
}

/**
 * Display a dialog to send a message to care team.
 * This handles the api POST to send the message.
 */
export default function MessageCareTeam({ open, onClose, ...rest }) {
  const testId = getTestId(rest, 'message-care-team-dialog')

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ 'data-testid': testId }}>
      <Content onClose={onClose} data-testid={testId} />
    </Dialog>
  )
}

function Content({ onClose, ...rest }) {
  const me = useMe()
  const assistant = me.provider.assistant
  const testId = getTestId(rest)

  const editor = useEditor({
    extensions,
    autofocus: 'end',
    editorProps: { clipboardTextParser },
  })

  const sendMessage = useSendMessage({ userId: me.provider?.assistantId })

  const handleSendMessage = () => {
    const message = editor.getJSON()
    return sendMessage
      .mutateAsync({ message: JSON.stringify(message) })
      .then(() => toast.success('Your message was sent'))
      .then(() => onClose())
  }

  useUserOutOfOfficeListener(assistant)

  return (
    <>
      <DialogTitle>Message Care Team</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <User data={assistant} />
          <Box sx={[outlineRichTextEditorStyling, richTextStyles, { '& .tiptap': { minHeight: 100, maxHeight: 300 } }]}>
            <EditorContent editor={editor} />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" data-testid={`${testId}-cancel`}>
          Cancel
        </Button>
        <LoadingButton
          loading={sendMessage.isPending}
          onClick={handleSendMessage}
          variant="contained"
          data-testid={`${testId}-send`}
          disabled={editor.isEmpty}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </>
  )
}
