import { useFormik } from 'formik'

import { severities } from '@shared/utils'

import { Button, Dialog, LoadingButton, MenuItem, Stack, TextField, Typography } from '@mui-components'
import FormHeader from '@components/Header/Form'
import InputControl from '@components/InputControl'
import { SlideUp } from '@components/Transitions'

import { getInitialValues, toApiData, validationSchema } from './Update.utils'

export default function AllergyUpdate({ data, onUpdate, open, onClose }) {
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={SlideUp}>
      <FormHeader title={data ? 'Edit Allergy' : 'New Allergy'} onClose={onClose} data-testid="allergy-update-drawer" />
      <Form allergy={data} onUpdate={onUpdate} onClose={onClose} />
    </Dialog>
  )
}

function Form({ allergy, onUpdate, onClose }) {
  const formik = useFormik({
    initialValues: getInitialValues(allergy),
    validationSchema,
    onSubmit: (values) => onUpdate(toApiData(values)).then(onClose),
  })

  return (
    <Stack sx={{ maxWidth: 'sm', width: '100%', mx: 'auto', px: 2, py: 3, gap: 3 }}>
      <Typography>Once the health history has been updated, any further changes can only be made by your healthcare provider.</Typography>
      <Stack spacing={2}>
        <InputControl field="name" formikProps={formik} variant="standard">
          <TextField autoFocus required fullWidth autoComplete="off" disabled={formik.isSubmitting} />
        </InputControl>
        <InputControl field="reaction" formikProps={formik} variant="standard">
          <TextField required fullWidth autoComplete="off" disabled={formik.isSubmitting} />
        </InputControl>
        <InputControl field="severity" formikProps={formik} variant="standard">
          <TextField required fullWidth autoComplete="off" disabled={formik.isSubmitting} select>
            {severities.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </InputControl>
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Button disabled={formik.isSubmitting} onClick={onClose} variant="outlined" data-testid="cancel">
          Cancel
        </Button>
        <LoadingButton loading={formik.isSubmitting} onClick={formik.handleSubmit} variant="contained" data-testid="submit">
          {allergy ? 'Update' : 'Create'}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
