import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogTitle } from '@mui-components'

MedicareDialog.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** Called after any action or the dialog is closed */
  onCancel: PropTypes.func.isRequired,

  /** Called when 'Yes' pressed */
  onConfirm: PropTypes.func.isRequired,

  /** Called when 'No' pressed */
  onReject: PropTypes.func.isRequired,
}

export default function MedicareDialog({ open, onCancel, onConfirm, onReject }) {
  const testId = 'medicare-selection-dialog'

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" PaperProps={{ 'data-testid': testId }}>
      <DialogTitle>Do you have Medi-Cal?</DialogTitle>
      <DialogActions>
        <Button onClick={onReject} data-testid={`${testId}-no`}>
          No
        </Button>
        <Button variant="contained" onClick={onConfirm} data-testid={`${testId}-yes`}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
