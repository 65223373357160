import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { DatePicker } from '@mui/x-date-pickers-pro'

import { Button, Dialog, LoadingButton, Stack, TextField, Typography } from '@mui-components'
import FormHeader from '@components/Header/Form'
import InputControl from '@components/InputControl'
import { SlideUp } from '@components/Transitions'

import { getInitialValues, toApiData, validationSchema } from './Update.utils'

export default function SurgeryUpdate({ data, onUpdate, open, onClose }) {
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={SlideUp}>
      <FormHeader title={data ? 'Edit Surgery' : 'New Surgery'} onClose={onClose} data-testid="surgery-update-drawer" />
      <Form surgery={data} onUpdate={onUpdate} onClose={onClose} />
    </Dialog>
  )
}

function Form({ surgery, onUpdate, onClose }) {
  const formik = useFormik({
    initialValues: getInitialValues(surgery),
    validationSchema,
    onSubmit: (values) => onUpdate(toApiData(values)).then(onClose),
  })

  return (
    <Stack sx={{ maxWidth: 'sm', width: '100%', mx: 'auto', px: 2, py: 3, gap: 3 }}>
      <Typography>Once the health history has been updated, any further changes can only be made by your healthcare provider.</Typography>
      <Stack spacing={2}>
        <InputControl field="name" formikProps={formik} variant="standard">
          <TextField autoFocus required fullWidth autoComplete="off" disabled={formik.isSubmitting} />
        </InputControl>
        <DatePicker
          minDate={dayjs('1901-01-01')}
          maxDate={dayjs()}
          value={formik.values.onsetYear}
          onChange={(year) => formik.setFieldValue('onsetYear', year, true)}
          disabled={formik.isSubmitting}
          views={['year']}
          slotProps={{
            textField: {
              label: 'Year',
              fullWidth: true,
              variant: 'standard',
              autoComplete: 'off',
              name: 'onsetYear',
              helperText: formik.touched.onsetYear && formik.errors.onsetYear,
              error: Boolean(formik.touched.onsetYear && formik.errors.onsetYear),
            },
          }}
        />
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Button disabled={formik.isSubmitting} onClick={onClose} variant="outlined" data-testid="cancel">
          Cancel
        </Button>
        <LoadingButton loading={formik.isSubmitting} onClick={formik.handleSubmit} variant="contained" data-testid="submit">
          {surgery ? 'Update' : 'Create'}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
