import { useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMe } from '@shared/providers/src/MeProvider'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { getTestId, handleError, TreatmentType } from '@shared/utils'

import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui-components'

import ConfirmHIVCBOSupport from './ConfirmHIVCBOSupport'
import ConfirmHIVNoCBOSupport from './ConfirmHIVNoCBOSupport'
import ConfirmInjectablePrEP from './ConfirmInjectablePrEP'
import ConfirmOralPrEP from './ConfirmOralPrEP'
import ConfirmSTITesting from './ConfirmSTITesting'
import { useCBOServices, useTreatmentUpdate } from './TreatmentDialog.hooks'

export default function TreatmentDialog() {
  const [selectedTreatment, setSelectedTreatment] = useState()

  const me = useMe()
  const theme = useTheme()
  const options = useLookup(Lookup.ProviderTypes)
  const cboOfferedServices = useCBOServices()

  const updateTreatment = useTreatmentUpdate()

  const patientCBOSupportsInjectablePrEP = cboOfferedServices?.includes(TreatmentType.InjectablePrEP) ?? false
  const patientCBOSupportsHIV = cboOfferedServices?.includes(TreatmentType.HIV) ?? false
  const patientCBOSupportsSTITesting = (cboOfferedServices?.includes(TreatmentType.STITesting) && me.insured) ?? false

  const handleConfirm = (props = {}) => {
    return updateTreatment
      .mutateAsync({
        active: true,
        provider_type_id: options[selectedTreatment],
        ...props,
      })
      .catch(handleError)
  }

  const Confirm = useMemo(() => {
    if (selectedTreatment === TreatmentType.OralPrEP) {
      return ConfirmOralPrEP
    }

    if (selectedTreatment === TreatmentType.InjectablePrEP) {
      return ConfirmInjectablePrEP
    }

    if (selectedTreatment === TreatmentType.HIV) {
      return patientCBOSupportsHIV ? ConfirmHIVCBOSupport : ConfirmHIVNoCBOSupport
    }

    if (selectedTreatment === TreatmentType.STITesting) {
      return ConfirmSTITesting
    }
  }, [patientCBOSupportsHIV, selectedTreatment])

  if (selectedTreatment) {
    return <Confirm loading={updateTreatment.isPending} onCancel={() => setSelectedTreatment()} onConfirm={handleConfirm} />
  }

  return (
    <Dialog open={Boolean(cboOfferedServices)} fullWidth maxWidth="xs" scroll="body">
      <DialogTitle>Welcome to {theme.app.name}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography color="text.secondary">What can we help you with today?</Typography>
          <Stack spacing={2} py={2}>
            <TreatmentButton
              label="Get Oral PrEP"
              onClick={() => setSelectedTreatment(TreatmentType.OralPrEP)}
              data-testid="oral-prep-treatment-button"
            >
              HIV prevention with a daily oral pill. Quarterly HIV/STI lab testing at home or in-person is included.
            </TreatmentButton>
            {patientCBOSupportsSTITesting && (
              <TreatmentButton
                label="Get Tested for STIs"
                onClick={() => setSelectedTreatment(TreatmentType.STITesting)}
                data-testid="sti-testing-treatment-button"
              >
                For patients seeking STI testing only.
              </TreatmentButton>
            )}
            {patientCBOSupportsInjectablePrEP && (
              <TreatmentButton
                label="Get Injectable PrEP"
                onClick={() => setSelectedTreatment(TreatmentType.InjectablePrEP)}
                data-testid="injectable-prep-treatment-button"
              >
                HIV prevention with a shot every two months, in-person shot (no pills). Bi-monthly HIV/STI testing at home or in-person
                included.
              </TreatmentButton>
            )}
            <TreatmentButton label="HIV+ Care" onClick={() => setSelectedTreatment(TreatmentType.HIV)} data-testid="hiv-treatment-button">
              For people living with HIV
            </TreatmentButton>
          </Stack>
          <Typography color="text.secondary" variant="body2">
            We are currently providing HIV+ care for patients in the following states: Alabama, Arkansas, California, Florida, Georgia,
            Idaho, Illinois, Maryland, North Carolina, Pennsylvania, South Carolina, Texas, Virginia, and Washington, D.C.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

function TreatmentButton({ label, onClick, children, ...rest }) {
  const testId = getTestId(rest)

  return (
    <Stack spacing={1}>
      <Button fullWidth variant="outlined" onClick={onClick} data-testid={testId} sx={{ px: 0 }}>
        {label}
      </Button>
      <Typography variant="body2">{children}</Typography>
    </Stack>
  )
}
