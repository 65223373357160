import { getTestId } from '@shared/utils'

import { useProfileMissingErrors } from '@pages/Profile'
import {
  AddIcon,
  EmojiPeopleIcon,
  ErrorOutlineIcon,
  HealthAndSafetyOutlinedIcon,
  HelpMoneyIcon,
  KeyboardArrowRightIcon,
  LocationOnIcon,
  MedicalInformationIcon,
  PersonIcon,
} from '@icons'
import { Fade, List, ListItemButton, ListItemIcon, ListItem as ListItemMui, ListItemText, useMediaQuery } from '@mui-components'

import { useProfileItem } from '../../EditProfile.hooks'

export default function Menu() {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const { data } = useProfileMissingErrors()

  return (
    <Fade in>
      <List component="nav" sx={{ minWidth: isTablet ? 220 : '100%', bgcolor: 'background.paper' }}>
        <ListItem value="profile" label="Profile" icon={<PersonIcon />} error={Boolean(data.profile)} data-testid="profile-menu-item" />
        <ListItem value="address" label="Address" icon={<LocationOnIcon />} error={Boolean(data.address)} data-testid="address-menu-item" />
        <ListItem
          value="insurance"
          label="Insurance"
          icon={<HealthAndSafetyOutlinedIcon />}
          error={Boolean(data.insurance)}
          data-testid="insurance-menu-item"
        />
        <ListItem
          value="health-assistance"
          label="Health Assistance"
          icon={<HelpMoneyIcon />}
          error={Boolean(data.health)}
          data-testid="health-assistance-menu-item"
        />
        <ListItem value="health-history" label="Health History" icon={<MedicalInformationIcon />} data-testid="health-history-menu-item" />
        <ListItem value="sexual-health" label="Sexual Health" icon={<AddIcon />} data-testid="sexual-health-menu-item" />
        <ListItem value="about" label="About me" icon={<EmojiPeopleIcon />} error={Boolean(data.about)} data-testid="about-me-menu-item" />
      </List>
    </Fade>
  )
}

function ListItem({ label, value, icon, error = false, ...rest }) {
  const [profileItem, setProfileItem] = useProfileItem()
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const isSelected = profileItem === value

  const testId = getTestId(rest)

  return (
    <ListItemMui
      disablePadding
      onClick={() => setProfileItem(value)}
      sx={{ backgroundColor: error ? 'error.shades.light.12' : 'inherit' }}
      data-testid={testId}
      data-item-selected={isSelected}
    >
      <ListItemButton selected={isSelected}>
        <ListItemIcon>{error ? <ErrorOutlineIcon color="error" /> : icon}</ListItemIcon>
        <ListItemText primary={label} primaryTypographyProps={{ color: error ? 'error' : 'inherit' }} />
        {!isTablet && <KeyboardArrowRightIcon color={error ? 'error' : 'inherit'} />}
      </ListItemButton>
    </ListItemMui>
  )
}
