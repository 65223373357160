import React, { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { useMe } from '@shared/providers/src/MeProvider'
import { getPhoneMasked, handleError, includesOneOfErrorMessages } from '@shared/utils'

import { Dialog, Link, Stack, Typography } from '@mui-components'
import FormHeader from '@components/Header/Form'
import OneTimeCode from '@components/OneTimeCode'
import { usePopup } from '@components/Popup/Popup'
import { SlideUp } from '@components/Transitions'

import { useSendVerification } from '../Profile.hooks'
import { useVerify } from './PhoneVerification.hooks'
import { popupContent } from './PhoneVerification.utils'

export default function PhoneVerification({ open, onClose }) {
  const [error, setError] = useState('')

  const me = useMe()
  const resend = useSendVerification()
  const verify = useVerify()
  const onInfo = usePopup({ content: popupContent })

  const phone = useMemo(() => {
    return getPhoneMasked(me.phone)
  }, [me.phone])

  const resendVerification = () => {
    return resend
      .mutateAsync({ channel: 'phone' })
      .then(() => toast.success('Verification code resent'))
      .catch(handleError)
  }

  const handleClose = () => {
    if (error) setError('')
    onClose()
  }

  const handleChange = (code) => {
    if (error) setError('')
    if (code.length === 6) {
      verify
        .mutateAsync({ code, channel: 'phone' })
        .then(() => handleClose())
        .catch((error) => {
          if (includesOneOfErrorMessages(error, ['does not match'])) {
            setError('Sorry, that’s the wrong code. Please try again.')
          } else {
            handleError(error)
          }
        })
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideUp}
      PaperProps={{ 'data-testid': 'verify-phone-dialog' }}
    >
      <FormHeader title="Verify Phone Number" onClose={handleClose} />
      <Stack spacing={8} sx={{ pt: 6, px: 3, textAlign: 'center' }}>
        <Stack spacing={2} sx={{ minHeight: 150 }}>
          <Typography variant="h6">I’ve sent a code to {phone}.</Typography>
          <Typography variant="h6">Enter it below so we make sure we have the correct number.</Typography>
        </Stack>
        <OneTimeCode error={error} loading={verify.isPending} onChange={handleChange} />
        <Stack spacing={4}>
          <Link variant="body1" onClick={() => resendVerification()} data-testid="resend-phone-code">
            Resend the code
          </Link>
          <Link variant="body1" onClick={() => onInfo()} data-testid="missing-phone-code">
            I didn’t get the code
          </Link>
        </Stack>
      </Stack>
    </Dialog>
  )
}
