import { useState } from 'react'

import { HealthHistoryType } from '@shared/utils'

import { MoreHorizIcon } from '@icons'
import { LoadingButton } from '@mui-components'

import { useCreateHealthHistory, useEditHealthHistory, useHealthHistory } from '../HealthHistory.hooks'
import { BaseCard } from '../HealthHistory.utils'
import Surgery from './Surgery'
import Update from './Update'

const type = HealthHistoryType.Surgeries

export default function Surgeries() {
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = useHealthHistory(type)

  const onEdit = useEditHealthHistory(type)
  const onCreate = useCreateHealthHistory(type)

  const handleUpdate = (data) => {
    if (selectedItem) {
      return onEdit.mutateAsync({ id: selectedItem.id, data })
    } else {
      return onCreate.mutateAsync(data)
    }
  }

  if (isPending) return <BaseCard.Loading />

  return (
    <>
      <BaseCard name="surgeries" items={data} hasMore={hasNextPage} onAdd={() => setIsCreateOpen(true)}>
        {data?.map((item) => (
          <Surgery
            key={item.id}
            data={item}
            onEdit={() => {
              setSelectedItem(item)
              setIsEditOpen(true)
            }}
          />
        ))}
        {hasNextPage && (
          <LoadingButton
            fullWidth
            loading={isFetchingNextPage}
            onClick={() => fetchNextPage()}
            endIcon={<MoreHorizIcon />}
            loadingPosition="end"
            sx={{ textTransform: 'none' }}
          >
            more
          </LoadingButton>
        )}
      </BaseCard>
      <Update
        data={selectedItem}
        onUpdate={handleUpdate}
        open={isCreateOpen || isEditOpen}
        onClose={() => {
          setIsCreateOpen(false)
          setIsEditOpen(false)
          setTimeout(() => setSelectedItem(null), 300)
        }}
      />
    </>
  )
}
