import React from 'react'
import PropTypes from 'prop-types'

import { getTestId } from '@shared/utils'

import { CloseIcon } from '@icons'
import { AppBar, IconButton, Toolbar, Typography } from '@mui-components'

Form.propTypes = {
  /** The title of the header */
  title: PropTypes.string.isRequired,

  /** The function to call when the close button is clicked */
  onClose: PropTypes.func,
}

export default function Form({ title, onClose, position = 'relative', ...rest }) {
  const testId = getTestId(rest, 'drawer')

  return (
    <AppBar elevation={0} sx={{ position, backgroundColor: 'primary.main' }}>
      <Toolbar>
        <Typography sx={{ flex: 1 }} variant="h6" component="div">
          {title}
        </Typography>
        {onClose && (
          <IconButton onClick={onClose} size="large" color="inherit" edge="end" data-testid={`${testId}-close`}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
}
