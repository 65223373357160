import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { DatePicker } from '@mui/x-date-pickers-pro'

import Mask from '@shared/components/src/Mask'
import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { useMe } from '@shared/providers/src/MeProvider'
import { Setting, useSetting } from '@shared/providers/src/SettingsProvider'
import {
  commPrefs,
  ContactMethod,
  getPhoneFormatted,
  handleError,
  includesOneOfErrorMessages,
  languages,
  pronouns,
  toBase64,
} from '@shared/utils'

import { Box, Button, Fade, Grid, LoadingButton, MenuItem, Stack, TextField, Typography, useMediaQuery } from '@mui-components'
import FileUploader from '@components/FileUploader'
import ImageCrop from '@components/ImageCrop'
import InputControl from '@components/InputControl'

import MissingInfo from '../components/MissingInfo'
import Title from '../components/Title'
import { usePatientUpdate } from '../Profile.hooks'
import PhoneVerificationDialog from './PhoneVerification'
import { usePatientUpdatePhotoId, useSendVerification } from './Profile.hooks'
import { formikDocIdToApiData, formikToApiData, getInitialValues, getMissingInfo, validationSchema } from './Profile.utils'

export default function Profile({ onClose }) {
  const me = useMe()
  const phoneSms = useSetting(Setting.PhoneNumbersForSms)
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [openDocIdImgCropper, setOpenDocIdImgCropper] = useState(false)
  const [docIdImgToCrop, setDocIdImgToCrop] = useState('')
  const [openPhoneVerification, setOpenPhoneVerification] = useState(false)

  const update = usePatientUpdate()
  const updatePhotoId = usePatientUpdatePhotoId()
  const verify = useSendVerification()

  const [verifyPhone, sendingPhoneCode] = usePromiseLoading(() => {
    return verify
      .mutateAsync({ channel: 'phone' })
      .then(() => setOpenPhoneVerification(true))
      .catch(handleError)
  })

  const [verifyEmail, sendingEmailLink] = usePromiseLoading(() => {
    return verify
      .mutateAsync({ channel: 'email', callback_url: '/app?drawer=profile' })
      .then(() => toast.success('We sent you an email with a link to verify your email address'))
      .catch(handleError)
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(me),
    validationSchema,
    onSubmit: (values, { setFieldError }) => {
      const oldPhone = me.phone

      return update
        .mutateAsync(formikToApiData(values))
        .then(() => {
          if (!values.photoId) return Promise.resolve()
          return updatePhotoId.mutateAsync(formikDocIdToApiData(values))
        })
        .then(() => {
          // Show the phone verification dialog if the phone number changed
          if (oldPhone !== values.phone && values.phone?.length) {
            setOpenPhoneVerification(true)
          } else {
            onClose()
          }
          return toast.success('Profile information updated')
        })
        .catch((e) => {
          if (includesOneOfErrorMessages(e, ['Phone is already taken'])) {
            setFieldError('phone', 'Phone is already in use')
          } else if (includesOneOfErrorMessages(e, ['Email has already been taken'])) {
            setFieldError('email', 'Email is already in use')
          } else {
            handleError(e, { showResponse: true })
          }
        })
    },
  })

  const handleSelectingPhotoId = async (files) => {
    if (files.length) {
      const base64 = await toBase64(files[0])
      setOpenDocIdImgCropper(true)
      setDocIdImgToCrop(base64)
    }
  }

  const handleCancelingDocIdToCrop = () => {
    setOpenDocIdImgCropper(false)
    setDocIdImgToCrop('')
  }

  const handleCroppingImage = (base64) => {
    // Cropper returns always jpeg
    formik.setFieldValue('docIdMimeType', 'image/jpeg')
    formik.setFieldValue('photoId', base64)
    setOpenDocIdImgCropper(false)
    setDocIdImgToCrop('')
  }

  const missingInfo = useMemo(() => getMissingInfo(me), [me])

  return (
    <Fade in>
      <form noValidate onSubmit={formik.handleSubmit}>
        {isPhone && <Title label="Profile" onClose={onClose} />}
        <MissingInfo data={missingInfo} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputControl field="nickname" formikProps={formik}>
                <TextField label="Chosen Name" fullWidth />
              </InputControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputControl field="firstName" error={Boolean(missingInfo.firstName)} formikProps={formik}>
                <TextField fullWidth required />
              </InputControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputControl field="lastName" error={Boolean(missingInfo.lastName)} formikProps={formik}>
                <TextField fullWidth required />
              </InputControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputControl field="phone" error={Boolean(missingInfo.phoneVerification)} formikProps={formik}>
                <TextField
                  fullWidth
                  InputProps={{ inputComponent: Mask.Phone }}
                  onBlur={() => {
                    // Set contact method to email if the phone changes to empty
                    if (!formik.values.phone) {
                      formik.setFieldValue('contactMethod', ContactMethod.Email)
                      formik.setFieldValue('preferredCommunication', 'Email')
                    }
                  }}
                />
              </InputControl>
            </Grid>
            {Boolean(missingInfo.phoneVerification) && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="error"
                  disabled={sendingPhoneCode}
                  onClick={() => verifyPhone()}
                  data-testid="verify-phone-button"
                >
                  Verify your phone
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputControl field="email" error={Boolean(missingInfo.email) || Boolean(missingInfo.emailVerification)} formikProps={formik}>
                <TextField fullWidth required />
              </InputControl>
            </Grid>
            {Boolean(missingInfo.emailVerification) && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="error"
                  disabled={sendingEmailLink}
                  onClick={() => verifyEmail()}
                  data-testid="verify-email-button"
                >
                  Verify your email
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePicker
                minDate={dayjs('1901-01-01')}
                maxDate={dayjs().subtract(18, 'year')}
                label="Date of Birth"
                value={formik.values.dob}
                onChange={(dob) => formik.setFieldValue('dob', dob, true)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    name: 'dob',
                    size: 'small',
                    required: true,
                    helperText: formik.touched.dob && formik.errors.dob,
                    error: Boolean(missingInfo.dob) || Boolean(formik.touched.dob && formik.errors.dob),
                    inputProps: { 'data-testid': `input-dob` },
                    FormHelperTextProps: { 'data-testid': `helper-dob` },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputControl field="language" error={missingInfo.language} formikProps={formik}>
                <TextField fullWidth select>
                  {languages.map((l) => (
                    <MenuItem key={l} value={l}>
                      {l}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputControl field="pronouns" formikProps={formik}>
                <TextField fullWidth select>
                  {pronouns.map((p) => (
                    <MenuItem key={p} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Stack spacing={2}>
                  <Typography variant="h6" fontWeight="bold">
                    Contact Preferences
                  </Typography>
                  <Typography>Please let us know the best way to contact you.</Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    Our team may reach out regarding your care or if additional information is needed for your account, prescriptions or lab
                    work.
                  </Typography>
                  <InputControl field="contactMethod" error={missingInfo.contactMethod} formikProps={formik}>
                    <TextField label="Preference" fullWidth select required disabled={!formik.values.phone}>
                      {Object.entries(ContactMethod).map(([label, value]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputControl>
                </Stack>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Stack spacing={2}>
                  <Typography variant="h6" fontWeight="bold">
                    Notifications
                  </Typography>
                  {me.smsOptOut && me.phone ? (
                    <Typography data-testid="opted-out">
                      You have opted out from receiving text messages. To start receiving text messages send START to{' '}
                      <Typography component="span" noWrap>
                        {getPhoneFormatted(phoneSms)}
                      </Typography>
                      .
                      <br />
                      <br />
                      While opted out we will send email messages for information about your account and care.
                    </Typography>
                  ) : (
                    <>
                      <Typography>
                        We only send notifications to you regarding important information about your account and healthcare.
                      </Typography>
                      <InputControl field="preferredCommunication" formikProps={formik}>
                        <TextField fullWidth select label="Send notifications to" disabled={!formik.values.phone}>
                          {commPrefs.map((p) => (
                            <MenuItem key={p} value={p}>
                              {p}
                            </MenuItem>
                          ))}
                        </TextField>
                      </InputControl>
                    </>
                  )}
                </Stack>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FileUploader
                error={Boolean(missingInfo.photoId)}
                files={formik.values.photoId ? [formik.values.photoId] : []}
                onChange={handleSelectingPhotoId}
                title="Photo of Government Issued ID"
                allowedDescription="PNG or JPG"
                dropzoneOptions={{
                  accept: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpeg', '.jpg'],
                  },
                }}
                data-testid="photo-id-uploader"
              />
              {openDocIdImgCropper && (
                <ImageCrop
                  file={docIdImgToCrop}
                  cropType="card"
                  onSave={handleCroppingImage}
                  onCancel={handleCancelingDocIdToCrop}
                  closeOnSave
                />
              )}
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => formik.resetForm()}
              disabled={!formik.dirty || formik.isSubmitting}
              variant="outlined"
              data-testid="profile-cancel-btn"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={formik.isSubmitting}
              disabled={!formik.dirty}
              type="submit"
              variant="contained"
              data-testid="profile-update-btn"
            >
              Update
            </LoadingButton>
          </Stack>
        </Box>
        <PhoneVerificationDialog
          open={openPhoneVerification}
          onClose={() => {
            onClose()
            setOpenPhoneVerification(false)
          }}
        />
      </form>
    </Fade>
  )
}
