import { Alert, AlertTitle } from '@mui-components'

/**
 * Displays a list of missing information
 *
 * @param data - Object with keys as field names and values as error messages
 * @constructor
 */
export default function MissingInfo({ data }) {
  if (Object.keys(data).length === 0) return

  return (
    <Alert severity="error" variant="filled" sx={{ mx: 2, mb: 3 }} data-testid="missing-information-alert">
      <AlertTitle>Missing information</AlertTitle>
      <ul style={{ paddingInlineStart: '16px' }} data-testid="missing-information-list">
        {Object.values(data).map((v) => (
          <li key={v}>{v}</li>
        ))}
      </ul>
    </Alert>
  )
}
