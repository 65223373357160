/* eslint-disable react/forbid-foreign-prop-types */
import React, { useMemo } from 'react'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'

import { camelToTitleCase } from '@shared/utils'

HookInputControl.propTypes = {
  /** An input/control component to render */
  children: PropTypes.node.isRequired,

  /** The property name used to provide data to the component */
  field: PropTypes.string.isRequired,

  /** Override the error indicator from outside */
  error: PropTypes.bool,
}

/**
 * A wrapper/helper to use React hook form with a component.
 *
 * This will inject a number of properties into the child component that are used
 * by react hook form to provide the standard behavior.
 * The "field" value will be used for the id, name, label, data-testid.
 * This wrapper is meant to be used with a FormProvider, so it has access to
 * context and controller.
 *
 * @example
 * <HookInputControl field="name">
 *   <TextField required />
 * </HookInputControl>
 */
export default function HookInputControl({ children, field: name, error, ...rest }) {
  const { field, fieldState } = useController({ name })

  // Pull any known props from the child for re-use
  const childRequired = children.props.required || false
  const childLabel = children.props.label
  const childOnChange = children.props.onChange
  const childOnBlur = children.props.onBlur
  const childHelperText = children.props.helperText
  const childSize = children.props.size || 'small'

  const hasHelperText = children.type.propTypes?.hasOwnProperty('FormHelperTextProps')

  // Calculate the label 'type'
  const label = useMemo(() => {
    // If label not provided, use the field name
    if (!childLabel) {
      return `${camelToTitleCase(field.name)}`
    }
    // If label is provided, and it is a string type, use it and extend with optional if not required
    if (typeof childLabel === 'string') {
      return `${childLabel}`
    }
    // If label is provided, and it is not a string type, assume it is a component and return it
    return childLabel
  }, [childLabel, field.name])

  const handleBlur = (e) => {
    // handle cases where the child component has an onBlur handler and we want to combine with hook's
    if (childOnBlur) childOnBlur(e)
    field.onBlur(e)
  }

  const additionalProps = {
    required: childRequired,
    variant: 'outlined',
    id: field.name,
    name: field.name,
    value: field.value,
    label,
    size: childSize,
    onChange: childOnChange ?? field.onChange,
    onBlur: handleBlur ?? field.onBlur,
    error: error || (fieldState.isTouched && Boolean(fieldState.error)),
    helperText: (fieldState.isTouched && fieldState.error?.message) || childHelperText,
    inputProps: { 'data-testid': `input-${field.name}`, required: false, ...children.props.inputProps },
    ...(hasHelperText && { FormHelperTextProps: { 'data-testid': `helper-${field.name}` } }),
    ...rest,
  }

  return React.cloneElement(children, additionalProps)
}
