import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui-components'

NoCBODialog.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** Called after any action or the dialog is closed*/
  onCancel: PropTypes.func.isRequired,

  /** Called when selecting notify me */
  onNotifyMe: PropTypes.func.isRequired,

  /** Called when selecting contact customer support */
  onContactCustomerSupport: PropTypes.func.isRequired,
}

export default function NoCBODialog({ open, onCancel, onNotifyMe, onContactCustomerSupport }) {
  const testId = 'no-cbo-dialog'

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" PaperProps={{ 'data-testid': testId }}>
      <DialogTitle>No Community Partner Available</DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Typography sx={{ px: 3, py: 1 }}>
          There are no Community Partners that serve your new location.
          <br />
          <br />
          We must work with one to provide care. We do add community partners frequently and can notify you when one is available.
        </Typography>
        <Stack direction="column" spacing={1} sx={{ mt: 2, mb: 0, px: 1 }}>
          <Button fullWidth color="primary" onClick={onNotifyMe} sx={{ justifyContent: 'flex-start' }} data-testid={`${testId}-notify-me`}>
            Notify me
          </Button>
          <Button
            fullWidth
            color="primary"
            onClick={() => onContactCustomerSupport()}
            sx={{ justifyContent: 'flex-start' }}
            data-testid={`${testId}-customer-support`}
          >
            Contact Customer Support
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" data-testid={`${testId}-cancel`}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
